import { Typography, Paper, IconButton } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import GitHubIcon from '@material-ui/icons/GitHub';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
    translatorSectionTitle: {
        textAlign: 'left', padding: '20px', margin: '20px 0'
    },
    contactItem: { display: 'flex', alignItems: 'center', marginBottom: '10px' },
    typographyInContactItem: {
        marginLeft: '10px',
    },
}));

export function TranslatorContact() {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Paper elevation={3} className={classes.translatorSectionTitle}>
            <Typography variant="h5" gutterBottom><strong>{t('contactInfoTitle')}</strong></Typography>
            <Typography paragraph>{t('contactUs')}</Typography>
            {/* LinkedIn */}
            <div className={classes.contactItem}>
                <IconButton color="primary" component="a" href="https://www.linkedin.com/in/mynorxico/" target="_blank" rel="noopener noreferrer">
                    <LinkedInIcon />
                </IconButton>
                <Typography variant="body1" className={classes.typographyInContactItem}>
                    LinkedIn: Mynor Xico
                </Typography>
            </div>

            {/* GitHub */}
            <div className={classes.contactItem}>
                <IconButton color="primary" component="a" href="https://github.com/MynorXico" target="_blank" rel="noopener noreferrer">
                    <GitHubIcon />
                </IconButton>
                <Typography variant="body1" className={classes.typographyInContactItem}>
                    GitHub: MynorXico
                </Typography>
            </div>

            {/* Email */}
            <div className={classes.contactItem}>
                <IconButton color="primary" component="a" href="mailto:contact@mynorxico.me">
                    <EmailIcon />
                </IconButton>
                <Typography variant="body1" className={classes.typographyInContactItem}>
                    Email: contact@mynorxico.me
                </Typography>
            </div>
        </Paper>
    )
}