import config from '../../config';

import axios from 'axios';
import { ApiResponse } from './models/ApiResponse';

export interface translationResponse {
    data: string,
    src_text: string
}


// A mock function to mimic an async request for translation
export function fetchTranslation(src_text: string = "", src: string= "", tgt: string=""): Promise<ApiResponse> {
    return new Promise((resolve) => {
        if(src_text === ''){
            resolve({data: '', src_text: ''})
        }
        //let translatorBaseUrl = 'https://p6w9tkmzi1.execute-api.us-east-1.amazonaws.com/Prod/hello?query=';
        //let translatorBaseUrl = 'https://e77i6efazf.execute-api.us-east-1.amazonaws.com/Prod/translate?query=';
        let translatorBaseUrl = config.translatorApi;
        
        return axios.get(`${translatorBaseUrl}/translate_v2?query=${src_text}&model=${src}${tgt}`)
            .then(function (response) {
                if (response.status === 200) {
                    if (response.data.error == null) {
                        resolve({ data: response.data.translation, src_text })
                    } else {
                        resolve({ data: response.data.message, src_text })
                    }
                }
            })
            .catch(function (error) {
                console.log(error)
                resolve({ data: "Ocurrió un error al obtener la traducción", src_text })
            })
    }    );
}
