import { Typography, Paper } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export function TranslatorWelcome() {
    const {t} = useTranslation();
    return (
        <Paper elevation={3} style={{ textAlign: 'left', padding: '20px', marginBottom: '20px' }}>
            <Typography variant="h6" gutterBottom>
                <strong> {t('welcomeTitle')}</strong>
            </Typography>
            <Typography paragraph>
                {t('welcomeMessage')}
            </Typography>
        </Paper>
    )
}