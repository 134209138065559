import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: 'center',
    margin: '15%' //theme.spacing(10),
  },
  emoji: {
    fontSize: '4rem',
    marginBottom: theme.spacing(2),
  },
}));

export function NotFound() {
  const classes = useStyles();

  const { t } = useTranslation(); 

  return (
    <div className={classes.container}>
      <Typography variant="h4" gutterBottom>
        {t('notFoundPageTitle')}
      </Typography>
      <span role="img" aria-label="Sad face" className={classes.emoji.toString()}>
        😢
      </span>
      <Typography>
        {t('notFoundPageMessage')}
      </Typography>
      <Typography>
        {`${t('goBackTo')} `}
        <a href="/" style={{ textDecoration: 'underline' }}>
          {t('homePage')}
        </a>
        .
      </Typography>
    </div>
  );
}