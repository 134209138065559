import React from 'react';
import { Container, Typography, Link } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
  translatorFooter: {
    backgroundColor: theme.palette.background.default,
    marginTop: '20px',
    padding: '20px',
    color: 'rgba(0, 0, 0, 0.87)'
  },
  translatorFooterLink: {
    color: theme.palette.secondary.main
  }
}));

export function Footer() {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Container component="footer" className={classes.translatorFooter} maxWidth={false} >
      <Typography variant="body2" align="center">
        {t("spanishToKaqchikelTranslator")} | {t("developedBy")} Mynor Xico
      </Typography>
      <Typography variant="body2" align="center">
        © {new Date().getFullYear()} {t("allRightsReserved")}
      </Typography>
      <Typography variant="body2" align="center">
        <Link className={classes.translatorFooterLink} href="mailto:contact@mynorxico.me">{t("contact")}</Link>
      </Typography>
    </Container>
  );
}