import React, { useEffect } from 'react';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useDispatch } from 'react-redux'
import { TranslatorWelcome } from './TranslatorWelcome';
import { TranslatorGuide } from './TranslatorGuide';
import { TranslatorContact } from './TranslatorContactInfo';
import './Translator.module.css'
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom'
import SocialShare from '../social/SocialShare';
import { Facebook } from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { ThumbUp, ThumbDown } from '@material-ui/icons';
import { AppDispatch } from '../../app/store';
import { TranslatorDonate } from './TranslatorDonate';
import { fetchTranslation } from './translatorAPI';
import { setTranslationFeedback, sendTranslationSuggestion } from './translationFeedbackAPI';
import { ApiResponse } from './models/ApiResponse';
import { Card, CardActions, IconButton } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import Snackbar from '@material-ui/core/Snackbar';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
    container: {
        padding: '5%'
    },
    translatorTextField: {
        padding: "1em", paddingBottom: "0", width: "80%"
    },
    translatorTextFieldWithoutWordCounter: {
        padding: "1em 0.1em 1.5em 1em", width: "78%"
    },
    wordCounterLabel: {
        textAlign: "right", paddingRight: "3em", paddingTop: "0.5em", paddingBottom: "0.1em", fontSize: "0.8em"
    },
    disabledTextFieldInput: {
        backgroundColor: "#f5f5f5"
    }
}));



export function Translator() {
    const [value, setValue] = React.useState(0);

    const [sourceLanguage, setSourceLanguage] = React.useState<string>("es");
    const [targetLanguage, setTargetLanguage] = React.useState<string>("kaq");


    const supportedLanguages: {[key: string]: any} = {
        "es": {
            "default": "kaq",
            "supported": ["kaq", "qeq"]
        },
        "kaq": {
            "default": "es",
            "supported": ["es"]
        },
/*        "qeq": {
            "default": "es",
            "supported": ["es"]
        }*/
    }

    const handleSourceLanguageChange = (_: React.ChangeEvent<{}>, newValue: string) => {
        console.log("Handling source lang change:", newValue)
        setSourceLanguage(newValue);
        setTargetLanguage(supportedLanguages[newValue].default)
        setSourceInputValue('');
        setTranslation('');
    }

    const handleTargetLanguageChange = (_: React.ChangeEvent<{}>, newValue: string) => {
        console.log("Handling source lang change:", newValue)
        setTargetLanguage(newValue);
        setSearchParams({ srcLng: sourceLanguage, tgtLng: newValue})
        setSourceInputValue('');
        setTranslation('');
    }

    const [translation, setTranslation] = React.useState('');
    const [model, setModel] = React.useState('');

    const [isTranslating, setTranslationStatus] = React.useState(false);
    const [sourceInputValue, setSourceInputValue] = React.useState("");
    const [didTranslateUrlText, setDidTranslateUrlText] = React.useState(false);
    const [like, setLike] = React.useState(false)
    const [indicatedLike, setIndicatedLike] = React.useState(false)

    const dispatch = useDispatch<AppDispatch>();

    const classes = useStyles();
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const urlText = searchParams.get("text");
    const urlModel = searchParams.get("srcLng");
    const platforms = [
        {
            icon: <Facebook />,
            name: 'Facebook',
            shareUrl: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`,
            props: {
                width: 600,
                height: 400
            }
        },
    ];



    useEffect(() => {
        if (urlText != null && sourceLanguage != null && targetLanguage != null && !didTranslateUrlText) {
            setDidTranslateUrlText(true);
            setSourceInputValue(urlText);

            fetchTranslation(urlText, sourceLanguage, targetLanguage).then((data: ApiResponse) => {
                setTranslation(data.data);
            });
        }
        // if (!searchParams.get("srcLng") || !searchParams.get("tgtLng") ) {
        //     setSourceLanguage("es");
        //     setTargetLanguage("kaq")
        //     setSearchParams({ srcLng: "es", tgtLng: "kaq" })
        //     console.log("Got here 1")
        // } else{
        //     setSourceLanguage(searchParams.get("srcLng") || "");
        //     setTargetLanguage(searchParams.get("tgtLng") || "");
        //     setSearchParams({ srcLng: searchParams.get("srcLng")||"", tgtLng: searchParams.get("tgtLng") || ""})
        //     console.log("Go here 2")
        // }
    }, [dispatch, urlText, didTranslateUrlText]);


    async function getTranslation(e: any) {
        let textToTranslate = e.target.value;
        setSearchParams({ text: textToTranslate, srcLng: sourceLanguage, tgtLng: targetLanguage })
        if (e.target.value.trim().split(" ").length > 50) {
            return
        }
        setSourceInputValue(textToTranslate)
        if (!isTranslating) {
            setTranslationStatus(true);
            let translation_src = null;
            while (translation_src !== e.target.value) {
                setTranslation(`${t("translating")}...`)
                await fetchTranslation(e.target.value, sourceLanguage, targetLanguage).then((data: ApiResponse) => {
                    translation_src = data.src_text;
                    setTranslation(data.data);
                    setIndicatedLike(false)
                })
            }
            setTranslationStatus(false);
        }
    }

    function getCurrentModel(){
        return sourceLanguage+targetLanguage;
    }

    function processLike() {
        if (!indicatedLike || (indicatedLike && !like)) {
            setTranslationFeedback(sourceInputValue, translation, getCurrentModel(), true)
        }
        setIndicatedLike(true);
        setLike(true);


    }

    function processDislike() {
        if (!indicatedLike || (indicatedLike && like)) {
            setTranslationFeedback(sourceInputValue, translation, getCurrentModel(), false)
        }
        setIndicatedLike(true);
        setLike(false);
    }

    // Suggestion fields
    const [suggestion, setSuggestion] = React.useState('');
    const [isSuggestionMode, setIsSuggestionMode] = React.useState(false);
    const processSuggestionMode = () => {
        setIsSuggestionMode(true);
        setSuggestion('');
    }
    const handleSetSuggestion = (e: any) => {
        setSuggestion(e.target.value);
    }
    const handleCancelSuggestion = (e: any) => {
        setIsSuggestionMode(false)
    }
    const handleSubmitSuggestion = (e: any) => {
        setIsSuggestionMode(false)
        sendTranslationSuggestion(sourceInputValue, translation, getCurrentModel(), suggestion).then(() => {
            console.log("Opening snackbar")
        });
        setSnackbarOpen(true);

    }
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const handleSnackbarClose = (event: any, reason: any) => {
        setSnackbarOpen(false);
    };

    const supportsLanguage = (lang: string) => {
        return supportedLanguages[sourceLanguage].supported.includes(lang);
    }


    return (
        <div className={classes.root}>
            <Container maxWidth="lg" className={classes.container} >
                <Grid container spacing={1}>
                    <Grid item sm={12} xs={12}>
                        <TranslatorWelcome />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <Card>
                            <AppBar position="static">
                                <Tabs value={sourceLanguage} onChange={handleSourceLanguageChange} aria-label="translate-from-tabs">
                                    <Tab label={t("spanish")}  value="es" id="translate-from-tab-es" aria-controls='translate-from-paper' />
                                    <Tab label={t("Kaqchikel")}  value="kaq" id="translate-from-tab-kaq" aria-controls='translate-from-paper' />
                                    {/*<Tab label={t("Q'eqchi'")} value="qeq" id="translate-from-tab-kaq" aria-controls='translate-from-paper' />*/}
                                </Tabs>
                            </AppBar>
                            <Paper id="translate-from-paper" elevation={3}>
                                <TextField
                                    className={classes.translatorTextField}
                                    id="translate-from-input"
                                    multiline
                                    rows={5}
                                    value={sourceInputValue}
                                    placeholder={t("writeText")}
                                    inputProps={{ 'data-hj-allow': 'true' }}
                                    onChange={getTranslation}
                                    disabled={isSuggestionMode}
                                />
                                <div className={(classes.wordCounterLabel).toString()}>
                                    {sourceInputValue.trim().split(" ").length} {t("outOf")} 50 {t("words")}
                                </div>
                            </Paper>
                        </Card>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <Card>
                            <AppBar position="static">
                                <Tabs value={targetLanguage} onChange={handleTargetLanguageChange} aria-label="translate-to-tabs">
                                    {supportsLanguage("es") ? <Tab label={t("spanish")}  value="es" id="translate-to-tab-es" aria-controls='translate-to-paper'/> : <></>}
                                    {supportsLanguage("kaq") ? <Tab label={t("kaqchikel")} value="kaq" id="translate-to-tab-kaq" aria-controls='translate-to-paper' /> : <></>}
                                    {supportsLanguage("qeq") ? <Tab label={t("Q'eqchi'")}  value="qeq" id="translate-to-tab-es" aria-controls='translate-to-paper' /> : <></>}
                                </Tabs>
                            </AppBar>
                            <Paper id="translate-to-paper" elevation={0} className={classes.disabledTextFieldInput}>
                                <TextField
                                    className={classes.translatorTextFieldWithoutWordCounter}
                                    onChange={handleSetSuggestion}
                                    disabled={!isSuggestionMode}
                                    id="translate-to-input"
                                    multiline
                                    rows={5}
                                    placeholder={isSuggestionMode ? t('enterTheTranslation') : t('translation')}
                                    value={isSuggestionMode ? suggestion : translation}
                                    inputProps={{ 'data-hj-allow': 'true' }}
                                />
                                <SocialShare platforms={platforms} />
                            </Paper>
                            <CardActions style={{ justifyContent: "flex-end", padding: 0 }}>
                                {isSuggestionMode ? (
                                    <>
                                        <Tooltip title={t("sendSuggestion")}>
                                            <IconButton disabled={!suggestion} color={"inherit"} onClick={handleSubmitSuggestion}>
                                                <CheckIcon></CheckIcon>
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={t("cancelEdition")}>
                                            <IconButton color={"primary"} onClick={handleCancelSuggestion}>
                                                <ClearIcon></ClearIcon>
                                            </IconButton>
                                        </Tooltip>

                                    </>
                                ) : (<></>)}

                                <Tooltip title={t("suggestTranslation")}>
                                    <IconButton color={(isSuggestionMode) ? "primary" : "default"} disabled={!translation || isSuggestionMode} onClick={processSuggestionMode}>
                                        <EditIcon></EditIcon>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={t("like")}>
                                    <IconButton color={(indicatedLike && like) ? "primary" : "default"} disabled={!translation || isSuggestionMode} onClick={processLike}>
                                        <ThumbUp ></ThumbUp>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={t("dislike")}>
                                    <IconButton color={(indicatedLike && !like) ? "primary" : "default"} disabled={!translation || isSuggestionMode} onClick={processDislike}>
                                        <ThumbDown></ThumbDown>
                                    </IconButton>
                                </Tooltip>


                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <TranslatorGuide />
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <TranslatorDonate />
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <TranslatorContact />
                    </Grid>
                </Grid>
            </Container>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                autoHideDuration={2000}
                open={snackbarOpen}
                onClose={(handleSnackbarClose)}
                message={t("yourSuggestionHasBeenSaved")}
            ></Snackbar>
        </div>
    );
}