import React from 'react';
import { Translator } from './features/translator/Translator';
import { NotFound } from './features/notFound/NotFound';
import { Header } from './features/header/Header'
import { Footer } from './features/footer/Footer';
import { ThemeProvider } from '@material-ui/core/styles';
import { MainTheme } from './styles/Theme';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import TagManager from 'react-gtm-module'
import config from './config.js';
import './App.css';

function App() {
  const tagManagerArgs = {
    gtmId: config.gtagId
  }

  if(config.gtagId != null  && config.gtagId !== ""){
    TagManager.initialize(tagManagerArgs)
  }
  

  return (
    <Router >
      <div className="App">
        <ThemeProvider theme={MainTheme}>
          <Header />
          <Routes >
            <Route path="/" element={<Translator />} />
            <Route path="*" element={<NotFound />} />
          </Routes >
          <Footer />
        </ThemeProvider>
      </div>
    </Router>

  );
}

export default App;
