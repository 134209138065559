import config from '../../config';

import axios from 'axios';
import { ApiResponse } from './models/ApiResponse';

export interface translationFeedbackResponse {
    message?: string,
    error?: string
}

export function setTranslationFeedback(sourceText: string, targetText: string, model: string, like: boolean): Promise<translationFeedbackResponse> {
    return new Promise((resolve) => {
        if (sourceText === '') {
            resolve({  })
        }
        let translatorBaseUrl = config.translationFeedbackApi;

        return axios.put(`${translatorBaseUrl}/translation-score`, 
            {
                model: model,
                sourceText: sourceText,
                targetText: targetText,
                like: like
            })
            .then(function (response) {
                if (response.status === 200) {
                    resolve(response.data)
                }
            })
            .catch(function (error) {
                console.log(error)
                resolve({ error })
            })
    });
}

export function sendTranslationSuggestion(sourceText: string, targetText: string, model: string, alternateTranslation: string): Promise <translationFeedbackResponse> {
    return new Promise((resolve) => {
        let translationBaseUrl = config.translationFeedbackApi;

        return axios.put(`${translationBaseUrl}/translation-suggestion`,{
            model: model,
            sourceText: sourceText,
            targetText: targetText,
            alternateTranslation: alternateTranslation
        }).then(function(response){
            if  (response.status === 200){
                resolve(response.data)
            }
        })
        .catch(function(error){
            console.log(error)
            resolve({error})
        })
    })
}
