import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    width: 'auto',
    height: 'auto'

  },
  headerText: {
    fontSize: '1.5rem',
  },
}));

export function Header() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar variant="dense">

          <div className={classes.title}>
            <IconButton edge="start" color="inherit" aria-label="menu">
              <Avatar className={classes.logo} alt="Logo" src="/logo-76x76.webp" />
            </IconButton>
            <Typography variant="h6" className={classes.headerText}>
              {t("spanishToKaqchikelTranslator")}
            </Typography>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}
