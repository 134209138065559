import React from 'react';
import Button from '@material-ui/core/Button';

const BuyMeACoffeeButton = () => {
  const handleButtonClick = () => {
    // Add your BuyMeACoffee link here
    window.open('https://ko-fi.com/xicomynor', '_blank');
  };

  return (

        <Button variant="contained" color="primary" onClick={handleButtonClick}>
        <img src="/ko-fi.webp" alt="Support me on Ko-fi" />
      </Button>
  
    
  );
};

export default BuyMeACoffeeButton;
