import { Typography, IconButton, Paper, Collapse } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import BuyMeACoffeeButton from "../social/Coffee";
import { useState } from 'react';

const useStyles = makeStyles(() => ({
    translatorSectionTitle: {
        textAlign: 'left', padding: '20px', margin: '20px 0'
    },
    contactItem: { display: 'flex', alignItems: 'center', marginBottom: '10px' },
    typographyInContactItem: {
        marginLeft: '10px',
    },
    expandIcon: { float: 'right', marginTop: '-40px' },
}));

export function TranslatorDonate() {
    const classes = useStyles();

    const [expanded, setExpanded] = useState(false);
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    const { t } = useTranslation();
    return (
        <Paper elevation={3} className={classes.translatorSectionTitle}>
            <Typography variant="h5" gutterBottom>
                <strong>{t('donateInfoTitle')}</strong>
            </Typography>
            <IconButton
                className={classes.expandIcon}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
            >
                <ExpandMoreIcon />
            </IconButton>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <Typography paragraph>
                    {t("donateUs")}:
                </Typography>
                <BuyMeACoffeeButton/>


            </Collapse>
        </Paper>
    )
}
