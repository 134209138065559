import { createMuiTheme } from '@material-ui/core/styles';

export const MainTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#d35400', // Primary color
            contrastText: '#fff', // Text color that contrasts with primary color
        },
        secondary: {
            main: '#8e44ad', // Secondary color
            contrastText: '#fff', // Text color that contrasts with secondary color
        },
        error: {
            main: '#e74c3c', // Error color
            contrastText: '#fff',
        },
        warning: {
            main: '#f39c12', // Warning color
            contrastText: '#fff',
        },
        info: {
            main: '#3498db', // Info color
            contrastText: '#fff',
        },
        background: {
            default: '#f0f0f0', // Default background color
            paper: '#fff', // Paper/background color for cards or surfaces
        },
        text: {
            primary: '#333', // Primary text color
            secondary: '#666', // Secondary text color
        },
    },
});