import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import ShareIcon from '@material-ui/icons/Share';
import { objectToQueryString } from '../../utils/helpers';
import { Platform } from './models/Platform';

const useStyles = makeStyles(() => ({
    dial: {
        alignItems: 'end',
        float: 'right',
        paddingTop: '3%'
    }
}));

const SocialShare = ({ platforms }: { platforms: Platform[] }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [open, setOpen] = React.useState<boolean>(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleShare = (platform: Platform) => {
        window.open(platform.shareUrl, `${t('shareOn')} ${platform.name}`, objectToQueryString(platform.props))
    };
    const handleOpen = () => {
        setOpen(true);
    };
    return (<div className={classes.dial.toString()}>
        <SpeedDial
            ariaLabel={t('share')}
            icon={<ShareIcon />}
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
            direction="up"
            FabProps={{ size: "small" }}
        >
            {platforms.map((platform) => (
                <SpeedDialAction
                    key={`share-on-${platform.name}`}
                    icon={platform.icon}
                    tooltipTitle={platform.name}
                    onClick={() => {
                        handleShare(platform);
                        handleClose();
                    }}
                />
            ))}
        </SpeedDial>
    </div>);
};

export default SocialShare;