import i18next from "i18next";
import { initReactI18next } from "react-i18next";
// Not using i18next http plugin because of performance
import esTranslations from './locales/es/translation.json';


i18next
    .use(initReactI18next)
    .init({
        fallbackLng: 'es',
        resources: {
            es: {
                translation: esTranslations
            }
        }
    })