import { Typography, IconButton, Paper, Collapse } from "@material-ui/core";
import EmailIcon from '@material-ui/icons/Email';
import GitHubIcon from '@material-ui/icons/GitHub';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation, Trans } from "react-i18next";
import React, { useState } from 'react';

const useStyles = makeStyles(() => ({
    translatorSectionTitle: {
        textAlign: 'left', padding: '20px', margin: '20px 0'
    },
    contactItem: { display: 'flex', alignItems: 'center', marginBottom: '10px' },
    typographyInContactItem: {
        marginLeft: '10px',
    },
    expandIcon: { float: 'right', marginTop: '-40px' },
}));

export function TranslatorGuide() {
    const classes = useStyles();

    const [expanded, setExpanded] = useState(false);
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    const { t } = useTranslation();
    return (
        <Paper elevation={3} className={classes.translatorSectionTitle}>
            <Typography variant="h5" gutterBottom>
                <strong>{t('instructionsTitle')}</strong>
            </Typography>
            <IconButton
                className={classes.expandIcon}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
            >
                <ExpandMoreIcon />
            </IconButton>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <Typography paragraph>
                    {t("instructionsWelcome")}:
                </Typography>
                <Typography paragraph>
                    <strong>{t("step")} 1:</strong> {t("step1Description")}
                </Typography>

                <Typography paragraph>
                    <strong>{t("step")} 2:</strong> {t("step2Description")}
                </Typography>

                <Typography paragraph>
                    <strong>{t("step")} 3:</strong> {t("step3Description")}
                </Typography>

                <Typography paragraph>
                    <strong>{t("step")} 4:</strong> {t("step4Description")}
                </Typography>

                <Typography paragraph>
                    {t("suggestions")}
                </Typography>
                <Typography paragraph>
                    <Trans i18nKey="questionsOrHelp">
                        Thanks for choosing our Spanish to Kaqchikel Translator <a href="mailto:contact@mynorxico.me">Contact us</a>.
                    </Trans>
                </Typography>


                    <IconButton color="primary" component="a" href="https://www.linkedin.com/in/mynorxico/" aria-label="LinkedIn Profile">
                        <LinkedInIcon />
                    </IconButton>
                    <IconButton color="primary" component="a" href="https://github.com/MynorXico" aria-label="GitHub Profile" >
                        <GitHubIcon />
                    </IconButton>

                    <IconButton color="primary" component="a" href="mailto:contact@mynorxico.me" aria-label="Email">
                        <EmailIcon />
                    </IconButton>

            </Collapse>
        </Paper>
    )
}
